import React from 'react'

const IframeForm = ({ formSrcURL, formWidth, formHeight, formFrameborder, formMerginheight, formMarginwidth, ...props }) => {
  return (
    <div className='formgo'>
      <iframe
        src={formSrcURL}
        width={formWidth}
        height={formHeight}
        frameBorder={formFrameborder}
        marginHeight={formMerginheight}
        marginWidth={formMarginwidth}
      >
        Loading…
      </iframe>
    </div>
  )
}

export { IframeForm }
