import React, { useState } from 'react'
//  import { Formik, Field, useField } from 'formik'
//  import { navigate } from 'gatsby'
//  import validationSchema from './validationSchema'
//  import Helmet from 'react-helmet'
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { IframeForm } from '../IframeForm'

//  const encode = (data) => {
//  return Object.keys(data)
//    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
//    .join('&')
// }

//  const MyCheckbox = ({ children, ...props }) => {
//  const [field, meta] = useField({ ...props, type: 'checkbox' })
//  return (
//    <>
//      <label className='checkbox'>
//        <input {...field} {...props} type='checkbox' />
//        {children}
//      </label>
//      {meta.touched && meta.error ? (
//        <div className='error'>{meta.error}</div>
//      ) : null}
//    </>
//    )
//  }

const ContactForm = () => {
  const [token, setToken] = useState()

  return (
  //    <Formik
  //      initialValues={{ firstName: '', lastName: '', email: '', message: '', acceptedTerms: false, acceptedContact: false }}
  //      validationSchema={validationSchema}
  //      onSubmit={(values, { setSubmitting }) => {
  //        fetch("/?no-cache=1", {                                 //eslint-disable-line
  //          method: 'POST',
  //          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  //          body: encode({
  //            // 'form-name': Event.target.getAttribute('name'),
  //            'g-recaptcha-response': token,
  //            ...values,
  //          }),
  //        })
  //          .then(() => {
  //            navigate('/contact/success')
  //            setSubmitting(false)
  //          })
  //          .catch(error => {
  //            console.log(error)
  //            alert("Error: Please Try Again!");                 //eslint-disable-line
  //            setSubmitting(false)
  //          })
  //      }}
  //      render={({
  //        errors,
  //        touched,
  //        isSubmitting,
  //        handleSubmit,
  //        handleReset,
  //      }) => (<GoogleReCaptchaProvider reCaptchaKey='6LfSJOoUAAAAACo5FptLy5inFhJmhIPF9E9ekwsN'>
  //        <form
  //          name='contact'
  //          onSubmit={handleSubmit}
  //          onReset={handleReset}
  //          netlify='true'
  //          // data-netlify='true'
  //          data-netlify-honeypot='bot-field'
  //          data-netlify-recaptcha='true'
  //        >
  //          <div className='field'>
  //            <div className='control'>
  //              <Field className='input' type='hidden' name='form-name' id='form-name' value='contact' />
  //            </div>
  //          </div>
  //
  //          <div className='field'>
  //            <div className='control is-invisible'>
  //              <Field className='input' type='text' name='bot-field' id='bot-field' />
  //            </div>
  //          </div>
  //
  //          <div className='field is-horizontal'>
  //            <div className='field-body'>
  //              <div className='field'>
  //                <label className='label'>First Name</label>
  //                <div className='control'>
  //                  <Field className='input' type='text' placeholder='First Name' name='firstName' id='firstName' />
  //                </div>
  //                {touched.firstName && errors.firstName && <small className='has-text-danger'>{errors.firstName}</small>}
  //              </div>
  //
  //              <div className='field'>
  //                <label className='label'>Last Name</label>
  //                <div className='control'>
  //                  <Field className='input' type='text' placeholder='Last Name' name='lastName' id='lastName' />
  //                </div>
  //                {touched.lastName && errors.lastName && <small className='has-text-danger'>{errors.lastName}</small>}
  //              </div>
  //            </div>
  //          </div>
  //
  //          <div className='field'>
  //            <label className='label'>Email</label>
  //            <div className='control'>
  //              <Field className='input' type='email' placeholder='Email' name='email' id='email' />
  //            </div>
  //            {touched.email && errors.email && <small className='has-text-danger'>{errors.email}</small>}
  //          </div>
  //
  //          <div className='field'>
  //            <label className='label'>Message</label>
  //            <div className='control'>
  //              <Field className='textarea' component='textarea' placeholder='Message' name='message' id='message' rows='6' />
  //            </div>
  //            {touched.message && errors.message && <small className='has-text-danger'>{errors.message}</small>}
  //          </div>
  //
  //          <div className='field'>
  //            <div className='control'>
  //              <MyCheckbox name='acceptedTerms'>
  //                &nbsp;&nbsp;I accept the <a href='https://www.iubenda.com/privacy-policy/67983109' target='_blank' rel='noreferrer'>terms and conditions</a>
  //              </MyCheckbox>
  //            </div>
  //          </div>
  //
  //          <div className='field'>
  //            <div className='control'>
  //              <MyCheckbox name='acceptedContact'>
  //                &nbsp;&nbsp;I agree that VeneHindustrial may contact me regarding products and services provided by them.
  //              </MyCheckbox>
  //            </div>
  //          </div>
  //
  //          <div className='field is-grouped is-pulled-right'>
  //            <div className='control'>
  //              <button className='button' type='reset'>Reset</button>
  //            </div>
  //            <div className='control'>
  //              <button className='button is-primary' type='submit' disabled={isSubmitting}>Submit</button>
  //            </div>
  //          </div>
  //
  //          <GoogleReCaptcha
  //            onVerify={token => {
  //              setToken(token)
  //            }}
  //          />
  //        </form>
  //    <Helmet>
  //      <script async defer src='https://formfacade.com/include/103480858364088919435/form/1FAIpQLScnbxIQzEkLilS0dNatqwnWv2LOVMx9asREF9xJEh1i_fIpJQ/classic.js?div=ff-compose' />
  //    </Helmet>
  //    <div id='ff-compose' />
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey='6LcXzbkcAAAAAInwGGprWnapc9WDKDiyxSSE4cnR'
        scriptProps={{
          async: true,
          appendTo: 'head',
        }}
      >

        <IframeForm
          formSrcURL='https://docs.google.com/forms/d/e/1FAIpQLScnbxIQzEkLilS0dNatqwnWv2LOVMx9asREF9xJEh1i_fIpJQ/viewform?embedded=true'
          formWidth='700'
          formHeight='1620'
          formFrameborder='0'
          formMerginheight='0'
          formMarginwidth='0'
        />

        <GoogleReCaptcha
          onVerify={token => {
            setToken(token)
          }}
        />
      </GoogleReCaptchaProvider>
    </>
  )
}

export { ContactForm }
