import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import { LinkIcon } from '../LinkIcon'
// import Button from '../button/button.component'

const Revue = (props) => {
  const { formID } = props
  const loadDuration = 1000
  // FORM_URL should be the same as the form action url pointed out above
  // const FORM_URL = `https://app.convertkit.com/forms/2648463/subscriptions`
  const FORM_URL = 'https://www.getrevue.co/profile/venehindustrial/add_subscriber'
  const [status, setStatus] = useState(null)
  const [emaill, setEmail] = useState('')
  const [isDisable, setDisable] = useState(true)
  // const [errors, setErrors] = useState([])
  const [currentForm, setCurrentForm] = useState(null)

  useEffect(() => {
    // console.log('UserEffecto')
    // console.log(formID)
    setCurrentForm(document.getElementById(formID))
    // console.log(document.getElementById(formID))
    // console.log(currentForm)
  }, [formID])

  function validateEmail (email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  function submitSubscription () {
    // console.log('SUBMITING')
    const email = document.getElementById(formID).querySelector('#member-email').getAttribute('value')
    console.log(currentForm)
    // console.log(email)
    const isEmailValid = validateEmail(email)
    // console.log(isEmailValid)
    const emailError = document.getElementById(formID).querySelector('#email_error')
    const subSuccess = document.getElementById(formID).querySelector('#subscribe_success')
    const subLoading = document.getElementById(formID).querySelector('#subscribe_loading')
    const revueForm = document.getElementById(formID).querySelector('#revue-form')

    if (isEmailValid) {
      subLoading.classList.remove('hidden')
      emailError.classList.add('hidden')
      setTimeout(() => {
        subLoading.classList.add('hidden')
        subSuccess.classList.remove('hidden')
        revueForm.submit()
      }, loadDuration)
    } else {
      subLoading.classList.remove('hidden')
      setTimeout(() => {
        resetViews()
        emailError.classList.remove('hidden')
      }, loadDuration)
    }
  }

  function resetViews () {
    // console.log(document.getElementById(formID))
    document.getElementById(formID).querySelector('#subscribe_success').classList.add('hidden')
    document.getElementById(formID).querySelector('#subscribe_loading').classList.add('hidden')
    document.getElementById(formID).querySelector('#email_error').classList.add('hidden')
  }

  const subscribeClick = event => {
    event.preventDefault()
    setCurrentForm(formID)
    // console.log('SubscribeClick')
    resetViews()
    submitSubscription()
  }

  const handleInputChange = event => {
    const { value } = event.target
    setEmail(value)
    // console.log(emaill)
    setDisable(false)
  }

  return (
    <div className=''>
      <div id={formID} className='inline-block'>
        <div id='revue-embed'>
          <form
            className='inline-block'
            action={FORM_URL}
            method='post'
            // onSubmit={handleSubmit}
            id='revue-form'
            name='revue-form'
            target='_blank'
          >
            <div className='field'>
              <div className='control is-expanded has-icons-left has-icons-right'>
                <input
                  className='input is-success is-rounded'
                  type='email'
                  // The name attribute should be the same as on you selected form.
                  name='member[email]'
                  id='member-email'
                  value={emaill}
                  placeholder='email@example.com'
                  onChange={handleInputChange}
                  required
                />
                <span className='icon is-small is-left'>
                  <i className='fas fa-envelope'
                  />
                </span>
                <span className='icon is-small is-right'>
                  <i className='fas fa-check'
                  />
                </span>
              </div>
            </div>
            <div className='field'>
              <div className='control'>
                <Button name='member[subscribe]' id='member-submit' type='submit' variant='flat' size='xxl' onClick={subscribeClick} disabled={isDisable}>
                  <span className='mr-2'>
                    <span className='hidden' id='subscribe_loading'>
                      {' '}
                      <LinkIcon icon='loading' />{' '}
                    </span>{' '}
                    Subscribe
                  </span>
                </Button>
              </div>
            </div>
          </form>
        </div>
        <span id='email_error' className='hidden'>
          <div className='notification is_danger is-light'>
            <strong>Please provide a valid email address.</strong>
          </div>
        </span>
        <span id='subscribe_success' className='hidden'>
          <div className='notification is-sucess is-light'>
            <strong>Thanks for subscribing!.</strong>
          </div>
        </span>
        <span>
          {status === 'SUCCESS' && <p>Please go confirm your subscription!</p>}
          {status === 'ERROR' && <p>Oops, Something went wrong! try again.</p>}
        </span>
      </div>
      <div className='revue-form-footer'>By subscribing, you agree with Revue’s <a target='_blank' rel='noopener noreferrer' href='https://www.getrevue.co/terms'>Terms of Service</a> and <a target='_blank' rel='noopener noreferrer' href='https://www.getrevue.co/privacy'>Privacy Policy</a>.
      </div>
    </div>
  )
}

export { Revue }
